.card_product {
    border-radius: 0;
}

.card_product .ant-card-body {
    position: relative;
    padding: 24px;
}

.card_product .ant-card-body .tag_estado {
    position: absolute;
    top: 5;
    left: 5;
    background: #32BAB0;
    color: #fff;
    border: none;
    text-transform: uppercase;
    border-radius: 0;
    z-index: 99;
}

.card_product .ant-card-body h4 {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1rem;
    margin-top: 40px;
}

.card_product .ant-card-body p {
    font-size: small;
}

.card_product .ant-card-body a {
    transition: all ease-in-out .4s;
    color: #32BAB0;
}

.card_product .ant-card-body a:hover{
    color: #32BAB0;
}

.card_product .ant-card-body .container_img {
    width: 100%;
    height: 260px;
}

.card_product .ant-card-body .container_img img {
    max-width: 100%;
    width: auto;
    height: auto;
    max-height: 260px;
}