.modal_login .ant-modal-content {
    border-radius: 10px;
}

/* .modal_login .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 50px;
}

.modal_login .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 50px;
}
 */

.modal_login .title_login {
    text-align: center;
    font-weight: 600;
}

.modal_login .input_login {
    font-size: 16px;
    height: 50px;
    padding-left: 12px;
}

.modal_login .select_login .ant-select-selector{
    font-size: 16px;
    height: 50px;
    padding-left: 12px;
}

.modal_login .btn_login {
    width: 100%;
    height: 50px;
    font-size: 16px;
}

.modal_login .text_login {
    padding: 5px 0;
    text-align: center;
    display: block;
    font-size: 16px;
}

.modal_login .pd_top {
    margin-top: 10px;
}

.modal_login .link_login {
    text-decoration: underline;
    transition: all ease-in-out 400ms;
    color: #32BAB0;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
}

.link_text {
    transition: all ease-in-out 400ms;
    cursor: pointer;
    
}

.link_text:hover {
    color: #32BAB0;
}

.text_term {
    color: #32BAB0;
    cursor: pointer;
}