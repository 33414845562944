.social_network {
    padding-left: 30px;
    display: flex;
}

.social_network > a {
    fill: #BDBDBD;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    display: block;
    transition: all ease-in-out .3s;
}

.social_network > a:hover {
    fill: #32BAB0;
}

.icon_network {
    width: 100%;
    height: 100%;
}