/* HOME */

/* Portada */
.home .cover {
    background-color: #fafafa;
    width: 100%;
    height: calc(100vh - 1.5%);
    padding: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* texto de la portada */
.home .cover h2, h1, h4 {
    text-transform: uppercase;
    color: #424242;
    margin: 0;
}
.home .cover h1 {
    font-weight: 700;
    font-size: 2.5rem;
}

.home .cover h1 span {
    color: #32BAB0;
}

.home .cover a {
    color: #32BAB0;
    margin-top: 40px;
    font-size: 1.4rem;
    border-bottom: 2px solid #32BAB0;
    border-width: 30%;
    
}

.body_home {
    padding: 0 5%;
}

/* Pasos para publicar */

.steps_main {
    text-align: center;
    margin-top: 5%;
    font-family: 'monserrat';
}

.steps_main h1 {
    font-weight: 700;
    color: #BDBDBD;
    padding-bottom: 3%;
}

.steps_main p {
    color: #BDBDBD;
    padding-top: 5px;
}

/* Que es upgrap */

.what_main h1 {
    text-align: center;
    font-weight: 700;
    color: #BDBDBD;
}
.what_main h3 {
    font-weight: 600;
    font-size: 1.5rem;
    color: #32BAB0;
}

/* categorias */

.categorias_main a {
    text-decoration: none;
}

.categorias_main h2 {
    font-weight: 700;
    display: inline;
    color: #BDBDBD;
}

.categorias_main .ant-col {
    padding: 0 10px;
}

.categorias_main .ant-col a {
    text-decoration: none;
}

.categorias_main .ant-col a:hover > .ant-card {
    background-color: #32BAB0;
        
    }
    .categorias_main .ant-col a:hover > h5 {
        color: #fff;
        border-bottom-color: #fff;
            
        }

.categorias_main .ant-col a .ant-card {
    transition: all ease-in-out .4s;
    border-radius: 0;
    border: none;
    background: #eeeeee ;
    height: 200px;
    margin-top: 10px;
}

.categorias_main .ant-col a .ant-card .ant-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.categorias_main .ant-col a .ant-card .ant-card-body h5 {
    transition: all ease-in-out .4s;
    font-weight: 700;
    color: #424242;
    font-size: 1.5rem;
    border-bottom: 2px solid #424242;
    border-bottom-width: 30%;
    position: absolute;
    top: 150%;
    left: 20px;
}

.categorias_main .ant-col a .ant-card .ant-card-body img {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 200px;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.35;
    
}

/* productos */

.product_main {
    margin-top: 1%;
    width: 100%;
}

.product_main h2 {
    font-weight: 700;
    color: #BDBDBD;
}