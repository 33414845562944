.menuapp {
    padding: 14px 5%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menuapp > .menuapp_left {
    display: flex;
    
}

.menuapp > .seach_box {
    display: flex;
    align-items: center;
}