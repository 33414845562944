.footer_ {
    text-align: center;
    width: 100%;
    background-position: top center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding-top: 120px;
    margin-top: 5%;
    padding-bottom: 20px;
}

.footer_ .a_footer {
    margin: 0 5px;
}

.footer_ .a_footer img {
    width: 25px;
}

.footer_ h1 {
    color: #fff;
    font-weight: 700;
    font-size: 2.8rem;
}

.footer_ div{
    color: #fff;

}

.footer_ a span {
    color: #fff;
}

.footer_ .ant-select-selection-item {
    color: #fff;
}