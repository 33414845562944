* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: monserrat;
  src: url('./assets/fonts/Montserrat-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: monserrat;
  src: url('./assets/fonts/Montserrat-Medium.ttf');
  font-weight: 500;
}


@font-face {
  font-family: monserrat;
  src: url('./assets/fonts/Montserrat-SemiBold.ttf');
  font-weight: 600;
}


@font-face {
  font-family: monserrat;
  src: url('./assets/fonts/Montserrat-Bold.ttf');
  font-weight: 700;
}


/* body {
  font-family: 'monserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 16px;
} */