.catalogo_container {
    width: 100%;
    height: 100vh;
    padding: 0 3em;
}

.catalogo_container .sidebar_products {
    padding-top: 1em;
}

.catalogo_container .sidebar_products .select_country {
    width: 100%;
}

.catalogo_container .products_container {
    padding: 1em;
    width: 100%;
    height: 100%;
}

.catalogo_container .products_container .ant-card-body {
    padding: 10px;
}

.catalogo_container .products_container a {
    width: 100%;
    height: 100%;
    position: relative;
}

.catalogo_container .products_container a .container_img {
    width: 100%;
    height: 200px;
    overflow: hidden;
    vertical-align: middle;
}

.catalogo_container .products_container a .container_im img {
    width: 100%;
    min-height: 200px;
    object-fit: fill;
}

.catalogo_container .products_container a .title_product_c {
    height: 57px;
    overflow: hidden;
}

.catalogo_container .products_container a .title_product_c h4 {
    font-weight: 600;
    font-size: 1rem;
    margin-top: 10px;
}


.catalogo_container .products_container a .description_container {
    height: 42px;
}

.catalogo_container .products_container a .description_container p {
    font-size: small;
}

.catalogo_container .products_container a a {
    transition: all ease-in-out .4s;
}

.catalogo_container .products_container a a:hover {
    color: #32BAB0;
}

.catalogo_container .products_container a .tag_estado {
    position: absolute;
    top: 5;
    left: 5;
    background: #32BAB0;
    color: #fff;
    border: none;
    text-transform: uppercase;
    border-radius: 0;
}