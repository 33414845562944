.customer_main {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.customer_main .row_customer {
    width: 100%;
    height: calc(100% - 68px);
    padding-top: 1em;
}

/* publicar y lista de productos */

.publish_main {
    padding: 0 20px;
}
.publish_main .inputsCode {
    width: 100%;
}

.cardProduct {
    margin-top: 10px;
}

.cardProduct .ant-card-body {
    padding: 8px;
}

.cardProduct .ant-card-body .ant-col {
    display: flex;
    align-items: center;
}

.cardProduct .ant-card-body .ant-col a {
    display: flex;
    align-items: center;  
}

.cardProduct .ant-card-body .ant-col a .description_card {
    margin-left: 20px;
}

.cardProduct .ant-card-body .ant-col a .description_card p {
    margin: 0;
}

/* editar datos */

.editdata_main {
    padding: 1em;
}

/* chat */
.chat_main {
    padding: 30px;
}

.chat_main .chat_card {
    width: 100%;
    margin-top: 10px;
    border-radius: 0 !important;
}

.chat_main .chat_card .ant-card-head {
    background: #32BAB0;
    color: #fff;
}

.chat_main .chat_card .ant-card-body .col_chat {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chat_main .chat_card .ant-card-body .col_chat .zone_messages {
    min-width: 100%;
    padding: 20px;
    overflow: auto;
    max-height: 62vh;
}

.chat_main .chat_card .ant-card-body .col_chat .zone_messages .container_right {
    width: 100%;
    text-align: right;
    margin-top: 8px;
}

.chat_main .chat_card .ant-card-body .col_chat .zone_messages .container_right .messageRight{
    margin: 0;
    background: #BDBDBD;
    border: none;
    display: inline-block;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.chat_main .chat_card .ant-card-body .col_chat .zone_messages .container_left {
    width: 100%;
    text-align: left;
    margin-top: 8px;
}

.chat_main .chat_card .ant-card-body .col_chat .zone_messages .container_left .mesageTag {
    background: #32BAB0;
    border: none;
    border-radius: 5px;
    margin: 0;
    color: #fff !important;
    display: inline-block;
    color: rgb(146, 134, 134);
    padding: 5px;
    text-align: left;
}

.chat_main .chat_card .ant-card-body .col_chat .zone_messages::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: #eeeeee;
}

.chat_main .chat_card .ant-card-body .col_chat .zone_messages::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #32BAB0;
}

.chat_main .chat_card .ant-card-body .col_chat .zone_input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 10%;
}

.chat_main .inputfile {
    position: absolute;
    visibility: hidden;
    z-index: 999;
}